/**
 * @file It contains scripts for user add view.
 */
import { mapGetters, mapActions } from "vuex"

export default {
  name: "UserAdd",
  data() {
    return {
      user: {
        name    : undefined,
        email   : undefined,
        jobTitle: undefined,
        phone   : undefined,
        groupIds: new Array()
      },
      isEmailDuplicate: false
    }
  },
  computed: {
    ...mapGetters({
      groups         : "groups/groups",
      isLoadingGroups: "groups/isLoadingGroups",
      isAddingUser   : "users/isAddingUser",
      isUserAdded    : "users/isUserAdded",
      userAddError   : "users/userAddError"
    })
  },
  methods: {
    ...mapActions({
      addUser: "users/addUser",
      notify : "shared/notify"
    })
  },
  watch: {
    isUserAdded: function(newValue) {
      if(newValue) {
        this.notify({
          type      : "success",
          text      : "431",
          parameters: {
            name: this.user.name
          }
        })
        this.$router.push({ name: "users" })
      }
    },
    userAddError: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          if (newValue.field === "email" && newValue.type === "duplicate") {
            this.isEmailDuplicate = true
          }
        }
      }
    }
  }
}
